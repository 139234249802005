gsap.registerPlugin(ScrollTrigger);

var el1 = document.getElementById("plus-icon-expand-1");
var el2 = document.getElementById("plus-icon-expand-2");

var el3 = document.getElementById("minus-icon-collapse-1");
var el4 = document.getElementById("minus-icon-collapse-2");

var watchVideoCapeCapital20 = document.querySelector(".cape-capital-watch");
var closeVideoIcon = document.querySelector(".video-element-close-icon");

var videoTarget = document.getElementById("cape-capital-20-years");

var sectionOneButton = document.querySelector(".main-section-btn");

if (sectionOneButton) {
    sectionOneButton.addEventListener("click", function () {
        if (window.innerWidth <= 1269) {
            gsap.to(window, { duration: 1, scrollTo: { y: ".video-section", offsetY: 90 } })
        } else {

            gsap.to(window, { duration: 1, scrollTo: { y: ".video-section" } })
        }
    })


}

if (el1) {
    el1.addEventListener("click", function () {
        gsap.to(".popper1", { duration: .2, y: "0" })
    })
}
if (el2) {
    el2.addEventListener("click", function () {
        gsap.to(".popper2", { duration: .2, y: "0" })
    })
}
if (el3) {
    el3.addEventListener("click", function () {
        gsap.to(".popper1", { duration: .2, y: "100%" })
    })
}
if (el4) {
    el4.addEventListener("click", function () {
        gsap.to(".popper2", { duration: .2, y: "100%" })
    })
}

gsap.to(".right-cut-img", {
    top: "0px",
    right: "0px",
    ease: "none",
    scrollTrigger: {
        trigger: ".right-cut",
        start: "top bottom",
        end: "bottom top",
        scrub: true
    }
});

$('.row-2-fifth-mobile').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 4,
    centerMode: true,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: false
            }
        },
    ]
});


var mode = "desktop";

function initialDisplayMode() {
    const desktopPosts = document.querySelector(".row-2-fifth");
    const mobilePosts = document.querySelector(".row-2-fifth-mobile");

    if (window.innerWidth <= 767) {
        desktopPosts.style.display = "none"
        mobilePosts.style.display = "block";
    } else {
        if (mobilePosts) {
            mobilePosts.style.display = "none";
        }
    }
}

window.onload = initialDisplayMode;
window.onload = twentyYear;

window.onresize = flowControl;

function flowControl() {
    const desktopPosts = document.querySelector(".row-2-fifth");
    const mobilePosts = document.querySelector(".row-2-fifth-mobile");

    //Controls pop-up elements on section 3
    if (window.innerWidth <= 767) {
        gsap.to(".popper1", { duration: .2, y: "0%" })
        gsap.to(".popper2", { duration: .2, y: "0%" })
    } else {
        gsap.to(".popper1", { duration: .2, y: "100%" })
        gsap.to(".popper2", { duration: .2, y: "100%" })
    }

    //Control the display of elements on section 5
    if (window.innerWidth <= 767) {
        desktopPosts.style.display = "none"
        mobilePosts.style.display = "block";
    } else {
        desktopPosts.style.display = "flex";
        mobilePosts.style.display = "none";
    }

}

if (watchVideoCapeCapital20) {
    watchVideoCapeCapital20.addEventListener("click", function () {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        gsap.to(".video-element", { duration: .5, bottom: "50%", y: "50%", zIndex: 5, display: "block" })
        gsap.to(".triangle-svg-main", { duration: .1, opacity: 0 })
    })
}

if (closeVideoIcon) {
    closeVideoIcon.addEventListener("click", function () {
        gsap.to(".video-element", { duration: .5, bottom: "-50vh", y: "100%", zIndex: -1, display: "none" })
        gsap.to(".triangle-svg-main", { duration: 2.5, opacity: 1 })
    })
}

function videoTriggerStart() {
    videoTarget.play();
}

function videoTriggerEnd() {
    videoTarget.pause();
}

function twentyYear() {

    const urlParams = new URLSearchParams(window.location.search);
    let hasTwentyYearsParam = urlParams.get("twenty")
    if (hasTwentyYearsParam) {
        console.log("works")
        if (window.innerWidth <= 1269) {
            gsap.to(window, { duration: 1, scrollTo: { y: ".video-section", offsetY: 90 } })
        } else {
            gsap.to(window, { duration: 1, scrollTo: { y: ".video-section" } })
        }
    }

}