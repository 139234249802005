var menuCurrentBackground = "";
var menuLastBackground = "";
var menuopen = false;
var links = ['Firm', 'AdvisoryServices', 'AssetManagement', 'Team', 'Impact', 'Perspectives', 'Connect']



    // id('headerLogo').addEventListener("click", function() {
    //     window.location.href = "https://capecapital.com/";
    // });

    // Open/close panel
    id('site-header_menu-trigger').addEventListener("click", function() {
        if (menuopen) {
            cl('Close menu');
            gsap.fromTo('#nav-text_left-panel', {x: '0%'}, {x: '-100%', duration: .5});
            gsap.fromTo('#nav-text_right-panel', {x: '0%'}, {x: '100%', duration: .5, onComplete: function() {
                if (!menuopen) {
                    id('nav-text').style.display = 'none';
                    id('nav-text').style.pointerEvents = 'none';
                    id('site-header').classList = 'site-header site-header_width-height_closed';
                }
            }});
            id('site-header_menu-trigger_l1').classList = "";
            id('site-header_menu-trigger_l2').classList = "";
            id('site-header_menu-trigger_l3').classList = "";
            menuopen = false;
        } else {
            cl('Open menu');
            id('site-header').classList = 'site-header site-header_width-height_open';
            id('nav-text').style.display = 'block';
            id('nav-text').style.pointerEvents = 'auto';
            gsap.fromTo('#nav-text_left-panel', {x: '-100%'}, {x: '0%', duration: .5});
            gsap.fromTo('#nav-text_right-panel', {x: '100%'}, {x: '0%', duration: .5});
            id('site-header_menu-trigger_l1').classList = "active";
            id('site-header_menu-trigger_l2').classList = "active";
            id('site-header_menu-trigger_l3').classList = "active";
            menuopen = true;
        }
    });

    // Navigation background animation
    id('navText_Firm').addEventListener("mouseover", function() {
        setTransition("nav-text_bgImage_Firm");
    });
    id('navText_AdvisoryServices').addEventListener("mouseover", function() {
        setTransition("nav-text_bgImage_AdvisoryServices");
    });
    id('navText_AssetManagement').addEventListener("mouseover", function() {
        setTransition("nav-text_bgImage_AssetManagement");
    });
    id('navText_Team').addEventListener("mouseover", function() {
        setTransition("nav-text_bgImage_Team");
    });
    id('navText_Impact').addEventListener("mouseover", function() {
        setTransition("nav-text_bgImage_Impact");
    });
    id('navText_Perspectives').addEventListener("mouseover", function() {
        setTransition("nav-text_bgImage_Perspectives");
    });
    id('navText_Connect').addEventListener("mouseover", function() {
        setTransition("nav-text_bgImage_Connect");
    });

function setTransition(value) {
    menuLastBackground = menuCurrentBackground;
    menuCurrentBackground = value;
    menuItemRollOver();
}

function menuItemRollOver() {
    if (menuLastBackground != "") {
        gsap.to("#" + menuLastBackground, {opacity: 0, duration: .5});
    }
    gsap.to("#" + menuCurrentBackground, {opacity: 1, duration: .5});
    id('nav-text_right-panel').addEventListener("mouseout", removeMenuRollOutListener);
}

function removeMenuRollOutListener(event) {
    cl('event.target = ' + event.target);
    //if (id('nav-text_right-panel') !== event.target) return;
    if (menuLastBackground != "") {
        gsap.to("#" + menuLastBackground, {opacity: 0, duration: .5});
        menuLastBackground = "";
    }
    if (menuCurrentBackground != "") {
        gsap.to("#" + menuCurrentBackground, {opacity: 0, duration: .5});
        menuCurrentBackground = "";
    }
}

function id(input) {
    return document.getElementById(input);
}

function cl(input) {
    console.log(input);
}

cl('>>>>>>>>>> header.js');
